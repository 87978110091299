import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Footer from "../components/organisms/Footer";

const SafetyAtTheCragsPage = (props) => {

  const beal = getImage(props.data.allImage.edges[0].node.photos[0]),
        safety1 = getImage(props.data.allImage.edges[0].node.photos[1]),
        safety2 = getImage(props.data.allImage.edges[0].node.photos[2]),
        safety3 = getImage(props.data.allImage.edges[0].node.photos[3]),
        safety4 = getImage(props.data.allImage.edges[0].node.photos[4]),
        safety5 = getImage(props.data.allImage.edges[0].node.photos[5]),
        safety6 = getImage(props.data.allImage.edges[0].node.photos[6]),
        allInformation = props.data.allMarkdownRemark.edges;

  return (
    <>
      <Seo title="Safety at the Crags" />
      <Header classes="header--hasLogo">
        <Logo link={true} classes="" />
      </Header>
      <main className="static main--hasLogo">
        <div className="container">
          <h1>Safety at the crags</h1>
          <div className="image image--center">
            <small>All diagrams courtesy of</small>
            <GatsbyImage image={beal} alt="Safety at the Crags" />
          </div>
          {allInformation.filter((a) => a.node.frontmatter.order === 0).map(content => (
            <div key={content.node.frontmatter.order} className="information" dangerouslySetInnerHTML={{ __html: content.node.html }} />
          ))}
          <div className="image image--info">
            <GatsbyImage image={safety1} alt="Safety at the Crags - Belaying" />
          </div>
          <div className="image image--info">
            <GatsbyImage image={safety2} alt="Safety at the Crags - Belaying" />
          </div>
          {allInformation.filter((a) => a.node.frontmatter.order === 1).map(content => (
            <div key={content.node.frontmatter.order} className="information" dangerouslySetInnerHTML={{ __html: content.node.html }} />
          ))}
          <div className="image image--info">
            <GatsbyImage image={safety3} alt="Safety at the Crags - Clipping of bolts" />
          </div>
          {allInformation.filter((a) => a.node.frontmatter.order === 2).map(content => (
            <div key={content.node.frontmatter.order} className="information" dangerouslySetInnerHTML={{ __html: content.node.html }} />
          ))}
          <div className="image image--info">
            <GatsbyImage image={safety4} alt="Safety at the Crags - Rope position while leading" />
          </div>
          <div className="image image--info">
            <GatsbyImage image={safety5} alt="Safety at the Crags - Rope position while leading" />
          </div>
          {allInformation.filter((a) => a.node.frontmatter.order === 3).map(content => (
            <div key={content.node.frontmatter.order} className="information" dangerouslySetInnerHTML={{ __html: content.node.html }} />
          ))}
          <div className="image image--info">
            <GatsbyImage image={safety6} alt="Safety at the Crags - Cleaning a sport route" />
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query CragSafetyQuery {
    allImage(filter: {id: {eq: "XuZmmNiCUmDA1FYuoxMP"}}) {
      edges {
        node {
          id
          photos {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                quality: 100
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/safety-at-the-crags"}}}) {
      edges {
        node {
          frontmatter {
            path
            order
          }
          html
        }
      }
    }
  }
`;

export default SafetyAtTheCragsPage;
